import React from "react";
import CardCart from "../components/Card/CardCart";
import ButtonCheckout from "../components/UI/Buttons/ButtonCheckout";

function Cart({ setActivePage, cart, forceUpdate }) {
    //console.log('cart displayed: ', cart);
    let prod = Object.values(cart.stored);
    
    // подсчитывает количество и стоимость товаров в корзине 
    // сделан без стейтов, т.к. все равно должен пересчитываться на каждый рендер
    let sumQuantity = 0, sumPrice = 0;

    prod.forEach(product => {
        sumQuantity += product.quantity; 
        sumPrice += product.price*product.quantity;
    });
    
    let cards = new Array();
    prod.forEach(product => {
        let new_card = <CardCart 
            className = "taste__card"
            title = {product.name}
            subtitle = {product.description}
            price = {product.price}
            cardSrc = {product.imageURLMiniature}
            item = {product}
            forceUpdate = {forceUpdate}
            cart = {cart}
        />
        cards.push(new_card);
    });

    // aromas
    let categories_18 = [
        '513ab57e-b209-11ee-0a80-032d001d7495',
        'ee1da28d-b5f6-11ee-0a80-14f000286c83',
        '4947ed06-b209-11ee-0a80-066b001dc47d'
    ];
    
    let categories_12 = [
        'ab22539f-b5f6-11ee-0a80-05fc0027a1a3', // ice editions
        '2ce4d356-b209-11ee-0a80-032d001d7154', // sweets and desserts
        'c3f08641-b0c4-11ee-0a80-0ea30001ed85' // fruits and drinks
    ];
    let glicerine = '450502d2-2d5a-11ee-0a80-00790012aadd';
    let nicotine = '4a94da25-2d5a-11ee-0a80-09140012defa';
    
    let got12ml = 0, got18ml = 0, gotNicotine = 0, gotGlicerine = 0;
    
    Object.values(cart.stored).forEach(product => {
        if (categories_18.includes(product.categoryId)) got18ml += product.quantity;
        else if (categories_12.includes(product.categoryId)) got12ml += product.quantity;
        else if (product.categoryId == glicerine) gotGlicerine += product.quantity;
        else if (product.categoryId == nicotine) gotNicotine += product.quantity;
    });
    
	return (
		<div className="cart">
			<h1 className="title">Кошик</h1>

            <p className = "aroma__header">
                    Вже додано: 
            </p>
            <div style = {{"padding-top": "1rem", "padding-bottom": "1rem", "display": "flex"}}>
                
                <br />
                
                <div className = "aroma__container" style = {{"margin-right": "1.5rem"}}>
                    <p className = "aroma__title">
                        Аром. 18 мл: <span style = {{"border-bottom": "1px solid gray"}}>{got18ml}</span> шт, 
                    </p>
                    <p className = "aroma__title">
                        Аром. 12 мл: <span style = {{"border-bottom": "1px solid gray"}}>{got12ml}</span> шт.
                    </p>   
                </div>
                <br />
                <div className = "aroma__container">
                    <p className = "aroma__title">
                        Нiкотин: &nbsp;&nbsp;<span style = {{"border-bottom": "1px solid gray"}}>{gotNicotine}</span> шт, 
                    </p>
                    <p className = "aroma__title">
                        Глiцерин: <span style = {{"border-bottom": "1px solid gray"}}>{gotGlicerine}</span> шт.
                    </p> 
                </div>
                            
            </div>
        
            <hr/> <br/>

			<div className="cart__content">
				<div className="cart__items taste__body">
					{ cards }
				</div>
				{ Object.keys(cart.stored).length ? (
                <div className="cart__right">
					<div className="cart__right-totalCount">
						<span>{sumQuantity}</span> товарів
					</div>
					<div className="cart__right-sum">
						<span>{sumPrice}</span> грн
					</div>
					<ButtonCheckout onClick={() => setActivePage("checkout")}>
						Оформити замовлення
					</ButtonCheckout>
					<p className="cart__right-text">
						Вказана ціна не є остаточною. Остаточну ціну ви отримаєте від
						менеджера при підтверджені замовлення.
					</p>
				</div>
                ) : "" }
				
			</div>
		</div>
	);
}

export default Cart;
