import Card from "../../components/Card/Card";
import axios from "axios";
import React from "react";

import Categories from "../../components/Categories/Categories";
import CatDropdown from "../../components/Categories/CatDropdown";

function Glicerin12Extra({ cart, forceUpdate }) {
	const [loadedData, setLoadedData] = React.useState({ products: [], size: 0 });
    
    let isDesktop = window.screen.orientation.type.includes("landscape");

	React.useEffect(() => {
        // deprecates requests, if data up to date already
        if (loadedData.size) return;
		axios.get("/get_glicerin_12")
        .then(res => setLoadedData(res.data));
	});
    
    let quantity = loadedData.size,
        prod = loadedData.products;

    let cards = new Array();
    let body = new Array();

	 let lastCategory = null;
     let lastParams = {title: "&hidden", className: "", id: "", isActive: false};
    
	 prod.forEach(product => {
	 	if (product.category !== lastCategory) {
            if(!lastCategory) lastCategory = product.categoryId;
            
            let newDD = <CatDropdown 
                            className={lastParams.className} 
                            id={lastParams.id}
                            title={lastParams.title} 
                            cards={[...cards]} 
                            isActive={lastParams.isActive} />
            body.push(newDD);
            cards = [];

	 		if (product.categoryId === "2ce4d356-b209-11ee-0a80-032d001d7154")
	 		{
                let title = (
	 		        <span>SWEETS & DESSERTS</span>
	 		    );
                lastParams = {title: title, className: "_rose", id: "sec3", isActive: isDesktop};
            }
                
	 		else if (product.categoryId === "c3f08641-b0c4-11ee-0a80-0ea30001ed85")
            {
	 			let title = (
	 		        <span>FRUITS & DRINKS</span>
	 			);
                lastParams = {title: title, className: "_orange", id: "sec1", isActive: true};
            }
	 		else if (product.categoryId === "ab22539f-b5f6-11ee-0a80-05fc0027a1a3")
            {
	 			let title = (
	 		        <span>ICE EDITIONS</span>
	 			);
                lastParams = {title: title, className: "_blue", id: "sec2", isActive: isDesktop};
            }
	 	}

        let quantity = 0;
        if (Object.keys(cart.stored).includes(product.code))
            quantity = cart.stored[product.code].quantity;
         
	 	let new_card = (
	 		<Card
	 			className="taste__card"
	 			title={product.name}
	 			subtitle={product.description}
	 			price={product.price}
	 			cardSrc={product.imageURLMiniature}
	 			item={product}
	 			cart={cart}
	 			forceUpdate={forceUpdate}
	 			quantity={quantity}
	 		/>
	 	);
	 	cards.push(new_card);
	 	lastCategory = product.category;
	 });
    let newDD = <CatDropdown 
                    className={lastParams.className} 
                    id={lastParams.id}
                    title={lastParams.title} 
                    cards={[...cards]} 
                    isActive={lastParams.isActive} />
    body.push(newDD);
    cards = [];

	// aromas
    let categories_18 = [
        '513ab57e-b209-11ee-0a80-032d001d7495',
        'ee1da28d-b5f6-11ee-0a80-14f000286c83',
        '4947ed06-b209-11ee-0a80-066b001dc47d'
    ];
    
    let categories_12 = [
        'ab22539f-b5f6-11ee-0a80-05fc0027a1a3', // ice editions
        '2ce4d356-b209-11ee-0a80-032d001d7154', // sweets and desserts
        'c3f08641-b0c4-11ee-0a80-0ea30001ed85' // fruits and drinks
    ];
    
    let glicerine = '450502d2-2d5a-11ee-0a80-00790012aadd';
    let nicotine = '4a94da25-2d5a-11ee-0a80-09140012defa';
    
    let got12ml = 0, got18ml = 0, gotNicotine = 0, gotGlicerine = 0;
    
    Object.values(cart.stored).forEach(product => {
        if (categories_18.includes(product.categoryId)) got18ml += product.quantity;
        else if (categories_12.includes(product.categoryId)) got12ml += product.quantity;
        else if (product.categoryId == glicerine) gotGlicerine += product.quantity;
        else if (product.categoryId == nicotine) gotNicotine += product.quantity;
    });

	return (
		<>
			<h1 className="title">
				Премiкс 12 мл <span>EXTRA/SALT</span>
			</h1>
			<div className="all-goods _mob">
				<span>{quantity}</span> товарів
			</div>

			<p className = "aroma__header">
                Вже додано: 
            </p>
            <div style = {{"padding-top": "1rem", "padding-bottom": "1rem", "display": "flex"}}>
                <br />
                <div className = "aroma__container" style = {{"margin-right": "1.5rem"}}>
                    <p className = "aroma__title">
                        Аром. 18 мл <span style = {{"border-bottom": "1px solid gray"}}>{got18ml}</span> шт, 
                    </p>
                    <p className = "aroma__title">
                        Аром. 12 мл <span style = {{"border-bottom": "1px solid gray"}}>{got12ml}</span> шт.
                    </p>   
                </div>
                <div className = "aroma__container">
                    <p className = "aroma__title">
                        Нiкотин: &nbsp;&nbsp;<span style = {{"border-bottom": "1px solid gray"}}>{gotNicotine}</span> шт, 
                    </p>
                    <p className = "aroma__title">
                        Глiцерин: <span style = {{"border-bottom": "1px solid gray"}}>{gotGlicerine}</span> шт.
                    </p> 
                </div>
                            
            </div>
        
            <hr/> <br/>

			<div className="taste-wrapper">
			    <Categories />
				<section className="taste">
					<div className="taste__body">{body}</div>
				</section>
			</div>
		</>
	);
}

export default Glicerin12Extra;
